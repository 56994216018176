import * as React from "react";
import {
  LightningBoltIcon,
  CloudIcon,
  GlobeAltIcon,
  StarIcon,
  CheckIcon,
  XIcon,
} from "@heroicons/react/outline";
import { StaticImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import scrollTo from "gatsby-plugin-smoothscroll";
import { Dialog } from "@headlessui/react";
import { useState, useRef } from "react";
import ContactForm from "../components/form";
import Seo from "../components/seo";

const IndexPage = () => {
  let [isOpen, setIsOpen] = useState(false);
  let closeButton = useRef(null);

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  return (
    <>
      <Seo />
      <main>
        <Dialog
          open={isOpen}
          onClose={closeModal}
          initialFocus={closeButton}
          className="fixed inset-0 z-10 overflow-y-auto"
        >
          <div className="flex items-center justify-center min-h-screen">
            <Dialog.Overlay className="fixed inset-0 bg-black opacity-50" />
            <div className="bg-white relative p-8 pt-12 rounded-xl">
              <button
                ref={closeButton}
                onClick={closeModal}
                className="absolute top-3 right-3"
                aria-label="Chiudi Modulo"
              >
                <XIcon className="w-6 h-6" />
              </button>
              <Dialog.Title className="text-2xl mb-4">
                Contattaci per maggiori informazioni
              </Dialog.Title>
              <ContactForm />
            </div>
          </div>
        </Dialog>

        <section className="header">
          <div className="container mx-auto px-6 py-4 flex flex-wrap items-center justify-center lg:justify-between gap-4">
            <div className="w-full lg:w-1/5 text-center lg:text-left">
              <StaticImage
                src="../images/logo-all-voip.png"
                alt="logo allVoip"
                placeholder="none"
                width={110}
              />
            </div>

            <div className="">
              <nav>
                <ul className="flex items-center space-x-4 text-sm lg:text-base lg:space-x-8 uppercase">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <button
                    className="uppercase"
                    onClick={() => scrollTo("#soluzione")}
                  >
                    Soluzione
                  </button>
                  <button
                    className="uppercase"
                    onClick={() => scrollTo("#gui")}
                  >
                    GUI
                  </button>
                  <li>
                    <a
                      href="https://cascinanet.bman.shop/all/3/Telefonia"
                      alt="AllVoip Shop"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Shop
                    </a>
                  </li>
                  <button
                    type="button"
                    className="uppercase"
                    onClick={openModal}
                  >
                    Contatti
                  </button>
                </ul>
              </nav>
            </div>
          </div>
        </section>

        <section className="bg-gradient-to-r from-green-400 to-green-500">
          <div className="container mx-auto py-12 lg:py-32 px-6 text-white flex flex-col items-center">
            <h1 className="text-4xl font-light mb-3">
              Prova subito la flessibilità del centralino Wildix, contattaci
              ora!
            </h1>
            <span className="block font-bold mb-6">
              Richiedi di essere contattato dal nostro staff per formulare una
              proposta adatta alla tua attività
            </span>
            <button
              type="button"
              onClick={openModal}
              className="uppercase bg-red-600 px-6 py-3 rounded-md hover:bg-red-700 transition-colors"
            >
              Richiedi subito un contatto
            </button>
          </div>
        </section>

        <section className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4">
          <div className="box-1 bg-gray-500 p-6 text-white text-center flex flex-col items-center">
            <LightningBoltIcon className="h-12 w-12 mb-6" />
            <h3 className="uppercase text-lg mb-2">Compatibilità totale</h3>
            <p>
              L’architettura innovativa del software permette di utilizzare le
              linee telefoniche, ISDN e VOIP già presenti in azienda, senza
              passare dalla rete internet.
            </p>
          </div>

          <div className="box-1 bg-gray-600 p-6 text-white text-center flex flex-col items-center">
            <CloudIcon className="h-12 w-12 mb-6" />
            <h3 className="uppercase text-lg mb-2">In cloud</h3>
            <p>
              Il centralino virtuale in cloud può integrare più sedi e uffici
              distaccati come se fossero una sede unica, senza la necessità di
              installare complicati software sul proprio server.
            </p>
          </div>

          <div className="box-1 bg-gray-700 p-6 text-white text-center flex flex-col items-center">
            <GlobeAltIcon className="h-12 w-12 mb-6" />
            <h3 className="uppercase text-lg mb-2">Lavorare ovunque</h3>
            <p>
              Gli stessi strumenti sempre a disposizione sia se si ha davanti un
              computer fisso, un portatile o anche uno smartphone e un tablet.
              La nuova interfaccia ti permette di operare ovunque.
            </p>
          </div>

          <div className="box-1 bg-gray-800 p-6 text-white text-center flex flex-col items-center">
            <StarIcon className="h-12 w-12 mb-6" />
            <h3 className="uppercase text-lg mb-2">
              I vantaggi del PBX virtuale
            </h3>
            <p>
              Il centralino virtuale semplifica la gestione del lavoro: report,
              rubriche condivise, personalizzazioni e caselle vocali sono alcuni
              dei vantaggi che aumentano la produttività dell’intera azienda.
            </p>
          </div>
        </section>

        <section className="bg-gradient-to-r from-yellow-600 to-red-700">
          <div className="container px-6 py-12 mx-auto flex gap-8 items-center flex-wrap">
            <div className="w-full lg:w-5/12">
              <StaticImage
                src="../images/tcall.png"
                alt="tCall software"
                placeholder="blurred"
              />
            </div>
            <div className="text-white space-y-3 flex-1">
              <h2 className="text-2xl font-light">
                Quanto vale la tua consulenza telefonica?
              </h2>
              <p>
                Scopri come farti pagare le tue consulenze da remoto con tCall,
                lo strumento online in grado di dare il giusto valore al tempo
                impiegato per il supporto telefonico!
              </p>
              <a
                href="https://www.tcall.it/"
                target="_blank"
                rel="noopener noreferrer"
                className="bg-white px-6 py-3 rounded-full text-gray-700 inline-flex hover:bg-gray-900 hover:text-white transition-colors"
              >
                Scopri tCall
              </a>
            </div>
          </div>
        </section>

        <section className="bg-gray-700">
          <div className="container mx-auto px-6 py-12 flex gap-8 items-center flex-wrap">
            <div className="lg:w-3/12">
              <StaticImage
                className="rounded-xl"
                src="../images/collaboration-1.webp"
                alt="Wildix Collaboration"
                placeholder="blurred"
              />
            </div>
            <div className="text-white space-y-3 flex-1">
              <h2 className="text-2xl font-bold">Unified Communications</h2>
              <p>
                Le soluzioni di comunicazione unificata comprendono tutti i
                sistemi di comunicazione che la vostra azienda utilizza
                quotidianamente: telefoni, messaggistica istantanea,
                videochiamate e altro ancora. Questi sono tutti disponibili in
                forma digitale e in un’unica interfaccia. Le soluzioni di
                comunicazione e collaborazione unificate sono già state adottate
                da aziende di tutto il mondo e di tutti i settori verticali per
                la loro facilità d’uso e flessibilità.
              </p>
              <p>
                Con la tecnologia UC di Wildix, tutte le comunicazioni sono
                disponibili direttamente nel vostro browser e accessibili da
                qualsiasi dispositivo. È possibile inviare e ricevere chiamate
                vocali, videochiamate, messaggi istantanei e molto altro ancora,
                in ufficio o anche nei dispositivi personali, con gli ultimi
                aggiornamenti distribuiti appena disponibili, per garantire
                sempre la massima comodità e sicurezza.
              </p>
            </div>
          </div>
        </section>

        <section className="bg-gray-100" id="soluzione">
          <div className="container mx-auto px-6 py-12 space-y-3">
            <h2 className="text-2xl font-light">
              La soluzione Bcall per il tuo centralino aziendale
            </h2>
            <p>
              Con la nostra soluzione ottieni la licenza per l’uso del software
              del centralino Wildix comprensivo del traffico telefonico, prezzo
              chiaro e tondo, decidi tu quando disattivare il rinnovo, non ci
              sono vincoli!
            </p>
            <p>
              <b>
                Bcall non prevede l'obbligo di acquisto di hardware dedicato
              </b>
              , in quanto puoi utilizzare quello che hai già (cuffie o telefoni
              Voip). Oppure se preferisci possiamo fornirti le nostre nuove
              periferiche, anche a noleggio!
            </p>
          </div>
        </section>

        <section className="bg-white">
          <div className="container mx-auto px-6 py-12">
            <div className="flex flex-wrap gap-8">
              <div className="flex-1">
                <h3 className="text-xl">
                  Bcall il centralino Voip in Cloud con tecnologia Wildix.
                </h3>
                <span className="block font-bold text-xl mb-3">
                  Semplice, efficiente e con rapido da configurare.
                </span>
                <ul className="list-inside list-disc">
                  <li>E’ una soluzione di comunicazione integrata</li>
                  <li>
                    Semplice ed economico, utilizzi la tua rete, i tuoi telefoni
                    e pc
                  </li>
                  <li>Tecnologia cloud, facile e scalabile</li>
                  <li>Nessun vincolo con operatore telefonico</li>
                  <li>Canone mensile (durata minima 6 mesi)</li>
                  <li>
                    Pagamento semplice e automatico tramite addebito ricorrente
                  </li>
                </ul>
              </div>
              <div className="w-full lg:w-1/2 flex flex-wrap lg:flex-nowrap gap-y-6">
                <ul className="flex flex-col w-full">
                  <li className="inline-flex">
                    <CheckIcon className="h-6 w-6 text-green-500 mr-1" />
                    Casella vocale/Voice mail
                  </li>
                  <li className="inline-flex">
                    <CheckIcon className="h-6 w-6 text-green-500 mr-1" />
                    Gestione avanzata gruppi di risposta
                  </li>
                  <li className="inline-flex">
                    <CheckIcon className="h-6 w-6 text-green-500 mr-1" />
                    Gestione illimitata del numero di linee telefoniche
                  </li>
                  <li className="inline-flex">
                    <CheckIcon className="h-6 w-6 text-green-500 mr-1" />
                    Totalmente indipendente dall'hardware grazie all'uso di
                    gateway
                  </li>
                  <li className="inline-flex">
                    <CheckIcon className="h-6 w-6 text-green-500 mr-1" />
                    Risponditore automatico multilivello
                  </li>
                  <li className="inline-flex">
                    <CheckIcon className="h-6 w-6 text-green-500 mr-1" />
                    Report chiamate
                  </li>
                  <li className="inline-flex">
                    <CheckIcon className="h-6 w-6 text-green-500 mr-1" />
                    Rubrica di sistema condivisa
                  </li>
                  <li className="inline-flex">
                    <CheckIcon className="h-6 w-6 text-green-500 mr-1" />
                    Chatbot
                  </li>
                </ul>

                <ul className="flex flex-col w-full">
                  <li className="inline-flex">
                    <CheckIcon className="h-6 w-6 text-green-500 mr-1" />
                    Integrazione con Outlook, Office 365, GSuite e Gmail
                  </li>
                  <li className="inline-flex">
                    <CheckIcon className="h-6 w-6 text-green-500 mr-1" />
                    Chat multiutente
                  </li>
                  <li className="inline-flex">
                    <CheckIcon className="h-6 w-6 text-green-500 mr-1" />
                    Integrazione whatsApp
                  </li>
                  <li className="inline-flex">
                    <CheckIcon className="h-6 w-6 text-green-500 mr-1" />
                    Integrazione con i principali CRM
                  </li>
                  <li className="inline-flex">
                    <CheckIcon className="h-6 w-6 text-green-500 mr-1" />
                    Trascrizione chiamate con IA
                  </li>
                  <li className="inline-flex">
                    <CheckIcon className="h-6 w-6 text-green-500 mr-1" />
                    Conference Call
                  </li>
                  <li className="inline-flex">
                    <CheckIcon className="h-6 w-6 text-green-500 mr-1" />
                    Registrazione delle chiamate
                  </li>
                </ul>
            </div>

            <div className="w-full mt-8">
              <div className="bg-green-100 p-12 flex flex-col items-center">
                <span className="block text-4xl font-thin">
                  La tecnologia Wildix ha molte funzioni. Ecco le più
                  importanti!
                </span>
                <button
                  onClick={openModal}
                  className="uppercase bg-red-600 px-6 py-3 rounded-md hover:bg-red-700 transition-colors inline-flex mt-8 text-white"
                >
                  Richiedi subito un contatto
                </button>
              </div>

              
              </div>
            </div>
          </div>
        </section>

        <section className="bg-blue-50" id="gui">
          <div className="container mx-auto px-6 py-12 space-y-8">
          
            <div className="flex gap-6 items-center">
              <div className="w-full lg:w-1/2 space-y-6">
              <h2 className="text-2xl font-bold ">
                  Piattaforma UC&C per la comunicazione aziendale
                </h2>
              <p>
                Grazie agli strumenti di Unified Communications e Collaboration,
                i tuoi dipendenti possono risparmiare fino al 25% del tempo
                dedicato alle comunicazioni quotidiane, guadagnando fino a 2 ore
                al giorno. Questo ti permette di ridurre le attività che fanno
                perdere tempo e concentrarti su vendite e strategie per
                aumentare i ricavi della tua azienda.
              </p>
              <p>
                Tuttavia, non tutte le soluzioni UC&C sono uguali. Quali sono i punti di forza della soluzione Bcall?
              </p>
              </div>
              <div className="w-full lg:w-1/2">
              <iframe allowtransparency="true" title="Wistia video player" allowFullscreen frameborder="0" scrolling="no" className="w-full h-96" class="wistia_embed" name="wistia_embed" src="https://fast.wistia.net/embed/iframe/t8v4fbwe3y"></iframe>

              </div>
            </div>

            <div className="space-y-3">
              <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 pt-6">
                <div>
                  <h3 className="text-lg font-bold mb-1">Facile da usare</h3>
                  <p>Intuitiva e facile da usare, non è richiesta alcuna formazione per l’utente finale.</p>
                </div>
                <div>
                  <h3 className="text-lg font-bold mb-1">Completamente web-based</h3>
                  <p>Completamente basata sul web con supporto nativo per WebRTC, per consentire chiamate audio e video dal browser senza installazione di alcun software aggiuntivo.</p>
                </div>
                <div>
                  <h3 className="text-lg font-bold mb-1">Realmente unificata</h3>
                  <p>Veramente unificata, poiché consente di gestire tutte le attività di comunicazione dalla stessa interfaccia utente, comprese le chat di gruppo e le videoconferenze.</p>
                </div>
              </div>
            </div>
            <div className="flex gap-8 items-center flex-wrap-reverse">
              <div className="flex-1">
                <StaticImage
                  className="rounded-xl"
                  src="../images/wildix-mobile-app.jpeg"
                  alt="Wildix App"
                  placeholder="blurred"
                />
              </div>
              <div className="w-full lg:w-1/2">
                <h2 className="text-2xl font-light ">
                  Utilizza Bcall anche in mobilità
                </h2>
                <span className="block text-4xl uppercase font-bold ">
                  APP PER IOS E ANDROID.
                </span>
              </div>
            </div>

            <div className="space-y-3">
              <p>
                I tuoi servizi e il tuo interno ovunque. Con l’APP di Wildix
                il tuo cellulare diventa il tuo interno aziendale e puoi
                verificare lo stato degli interni, accedere alla rubrica
                aziendale, chattare con i tuoi colleghi e fare molto altro
                ovunque tu sia.
              </p>
              <p>L’APP di Wildix è uno strumento che
                offre, con immediatezza e semplicità di gestione, molti servizi
                avanzati per la comunicazione e la collaborazione aziendale
                oltre alla possibilità di gestire telefonate sia da softphone
                che tramite un cellulare.</p>
            </div>
          </div>
        </section>

        <footer className="bg-gray-900">
          <div className="container mx-auto px-6 py-12 text-white grid  lg:grid-cols-2">
            <div>
              <span className="text-xs block text-center lg:text-left">
                &copy; 2021 Cascinanet S.r.l.
              </span>
              <a
                href="https://www.iubenda.com/privacy-policy/8074034"
                target="_blank"
                className="text-xs block text-center lg:text-left"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </a>
              <a
                href="https://www.iubenda.com/privacy-policy/8074034/cookie-policy"
                target="_blank"
                className="text-xs block text-center lg:text-left"
                rel="noopener noreferrer"
              >
                Cookie Policy
              </a>
            </div>

            <div>
              <span className="text-xs block text-center lg:text-right">
                Cascinanet S.r.l. | P.IVA e C.F. 02073620508 – R.E.A. 178835 |
                Cap. Sociale € 10.000 i.v.
              </span>
              <span className="text-xs block text-center lg:text-right">
               Wildix è un marchio di proprietà di Wildix OÜ – Holding Company.
              </span>
            </div>
          </div>
        </footer>
      </main>
    </>
  );
};

export default IndexPage;
